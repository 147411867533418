import React from 'react';
import cl from 'classnames';
import s from './TextBlock.module.scss';

const TextBlock = ({ title, text, ...props }) => (
  <div {...props}>
    <div className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
    <div className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
  </div>
);

export default TextBlock;
