import React from 'react';
import { withNamespaces } from 'react-i18next';

import './TermsOfServiceBody.scss';
import NotAMemberComponent from '../NotAMemberComponent';
import Api from '../../utils/api';
import TextBlock from '../shared/TextBlock';
import PageHeaderTitle from '../PageHeaderTitle';

class TermsOfServiceBody extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
  }

  render() {
    const { t, brand } = this.props;

    return (
      <div className='terms-page'>
        <PageHeaderTitle brandName={brand.name} title={t('page_terms_of_service:terms_of_service')} />
        <div className='terms-wrapper terms-wrapper_bg_gray terms-wrapper_borderBottom terms-page__block'>
          <div className='terms-innerWrapper'>
            <div className='terms-header'>
              <div className='terms-title_h1 terms-header__title'>
                {t('page_terms_of_service:h1_title')}
              </div>
              <div className='terms-header__subtitle'>
                {t('page_terms_of_service:last_updated')}
              </div>
            </div>
          </div>
        </div>
        <div className='terms-wrapper terms-page__block'>
          <div className='terms-innerWrapper terms-textBlockGrid'>
            <TextBlock className='terms-textBlockGrid__item' title={t('page_terms_of_service:title_1')} text={t('page_terms_of_service:text_1')} />
          </div>
        </div>
        <div className='terms-wrapper terms-page__block'>
          <div className='terms-innerWrapper terms-textBlockGrid'>
            <TextBlock className='terms-textBlockGrid__item' title={t('page_terms_of_service:title_2')} text={t('page_terms_of_service:text_2')} />
          </div>
        </div>
        <div className='terms-wrapper terms-page__block'>
          <div className='terms-innerWrapper terms-textBlockGrid'>
            <TextBlock className='terms-textBlockGrid__item' title={t('page_terms_of_service:title_3')} text={t('page_terms_of_service:text_3')} />
          </div>
        </div>
        <NotAMemberComponent
          brand={brand.name}
          text={t('page_terms_of_service:not_a_member')}
          buttonText={t('page_terms_of_service:get_anonine')}
          orderUrl={this.api.orderUrl()}
        />
      </div>
    );
  }
}

export default withNamespaces('page_terms_of_service')(TermsOfServiceBody);
